<script>
import { SuawMainContent } from "@/components";
import SignUpForm from "@/features/users/auth/components/SignUpForm.vue";

export default {
  name: "SignUp",
  components: { SuawMainContent, SignUpForm }
};
</script>

<template>
  <SuawMainContent size="small">
    <SignUpForm />
  </SuawMainContent>
</template>
